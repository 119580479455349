.Bio {
  display: flex;
  margin-bottom: 1.375rem;
}
  
.Bio img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}

.Pipette {
  font-size: 1.5rem;
  margin-top: -0.2rem;
  margin-right: 0.8rem;
}